@import "../../scss/variables";

.FAQ {
    color: $secondary-color;
    padding: 140px 0 160px 0;

    @include responsive(sm) {
        padding: 110px 0;
    }

    @include responsive(xs) {
        padding: 70px 0;
        padding-bottom: 100px;
    }
    
    &__content {
        @include flex(center, center, column);
        width: 100%;
    }

    &__title {
        font-weight: $second-font-weight;
        color: $primary-color;
        margin-bottom: 60px;
        font-size: 48px;
        text-align: center;
        @include responsive(md) {
           font-size: 38px;
        }
        @include responsive(sm) {
            font-size: 32px;
         }
         @include responsive(xs) {
            font-size: 26px;
            margin-bottom: 35px;
            line-height: 1.3;
         }
         @include responsive(xxs) {
            font-size: 24px;
         }
    }
}