@import '../../scss/variables';

#header-placeholder {
  width: 100%;
  height: 78px;
}

.header {
  background: $header-main-bg-gradient;
  position: relative;

  .container {
    position: relative;
  }

  &__logo {
    @include responsive(xs) {
      width: 170px;
    }

    @include responsive(xxs) {
      width: 150px;
    }
  }

  &__navbar {
    z-index: 9999;
    width: 100%;
    position: fixed;
    top: 0;
    padding: 20px 0;
    -webkit-transition: position 0.7s cubic-bezier(0.55, 0.06, 0.68, 0.19);
    -o-transition: position 0.7s cubic-bezier(0.55, 0.06, 0.68, 0.19);
    transition: position 0.7s cubic-bezier(0.55, 0.06, 0.68, 0.19);

    &.fixed--navbar {
      background: $green-color-2;
      -webkit-box-shadow: 0px 5px 15px $light-black-color;
              box-shadow: 0px 5px 15px $light-black-color;
      -webkit-backdrop-filter: blur(7.5px);
              backdrop-filter: blur(7.5px);
    }

    &-inner {
      width: 100%;
      z-index: 99;
      position: relative;
      @include flex(space-between, center, row);
    }
  }

  &__navigation {
    @include flex(space-between, center, row);

    &-mobile {
      display: none;
      visibility: hidden;
    }

    &-links {
      @include flex(center, center, row);
    }
    &-item {
      margin: 0 20px;
      @include center;
      position: relative;
  
      &::after {
        width: 0%;
        height: 2px;
        background-color: $secondary-color;
        position: absolute;
        bottom: -6px;
        content: '';
        -webkit-transform-origin: center;
            -ms-transform-origin: center;
                transform-origin: center;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
      }
  
      &:hover {
        &::after {
          width: 48%;
        }
      }
  
      &.active--item {
        &::after {
          width: 48%;
        }
      }
  
      @include responsive(lg) {
        margin: 0 14px;
      }
    }
  
    &-link {
      color: $secondary-color;
      font-weight: $second-font-weight;
  
      @include responsive(md) {
        font-size: 14px;
      }
    }


    @media (max-width: 900px) {
      display: none;
      &-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        visibility: visible;
        position: absolute;
        height: 100vh;
        width: 60%;
        background: rgba(0, 0, 0, 0.76);
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        top: 0px;
        right: -100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 20px 25px;
        -webkit-transition: right 0.8s ease;
        -o-transition: right 0.8s ease;
        transition: right 0.8s ease;
        z-index: 9999;
        &.open{
            right: 0;
        }
        @include responsive(sm) {
          width: 72%;
        }
        @include responsive(xs) {
          width: 100%;
        }
      }
      &.open {
        right: -30px;
      }

      &-links {
        padding-top: 150px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        @include responsive(xxs) {
          padding-top: 70%;
        }
      }

      &-item {
        margin: 0;
        margin-bottom: 40px;
      }

      &-link {
        font-size: 22px;
        font-weight: $second-font-weight;
      }
  }
}

  &__language {
    @include flex(space-between, center, row);
    gap: 6px;
    margin-left: 34px;

    display: none;

    span {
      font-weight: $second-font-weight;
    }

    @media (max-width: 900px) {
      margin: 0;
      margin-top: 20px;

      span {
        font-size: 22px;
      }
    }

    @include responsive(xxs) {
      span {
        font-size: 20px;
      }
    }
  }

  .navbar__open-icon {
    display: none !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;

    img {
      width: 44px;
    }

    @media (max-width: 900px) {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }

    @include responsive(xs) {
      img {
        width: 40px;
      }
    }

    @include responsive(xxs) {
      img {
        width: 38px;
      }
    }
  }

  .navbar__close-icon {
    cursor: pointer;
    display: none !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 3px 11px;
    border-radius: 10px;
    position: absolute;
    top: 48px;
    left: 48px;

    svg {
      font-size: 32px;
    }

    @media (max-width: 900px) {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }

    @include responsive(xxs) {
      svg {
        width: 35px;
        height: 32px;
      }
    }
  }
}
