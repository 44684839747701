@import "../../scss/variables";

.directions {
  background-color: $secondary-color;
  padding: 50px 0 128px 0;

  @include responsive(xs) {
    padding-bottom: 100px;
  }

  @include responsive(xxs) {
    padding-bottom: 80px;
  }

  &__content {
    width: 100%;
    @include flex(center, center, column);
  }

  &__header {
    max-width: 360px;
    margin: 0 auto;
    @include flex(center, center, column);
    gap: 8px;
    text-align: center;
    margin-bottom: 80px;

    @include responsive(xs) {
      margin-bottom: 50px;
    }

    @include responsive(xxs) {
      margin-bottom: 30px;
    }

    h2 {
      color: $primary-color;

      @include responsive(sm) {
        font-size: 32px;
      }

      @include responsive(xs) {
        font-size: 26px;
      }

      @include responsive(xxs) {
        font-size: 22px;
      }
    }

    h5 {
      font-weight: $font-weight;
      font-size: 18px;
      line-height: 20px;
      color: $primary-color;

      @include responsive(xxs) {
        font-size: 16px;
      }
    }
  }

  &__cards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    @include responsive(md) {
      flex-direction: column;
    }
  }

  &__card {
    background: $primary-bg-gradient;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    flex-basis: 48%;
    min-height: 290px;
    @include responsive(md) {
      flex-basis: none;
      margin-inline: auto;
      max-width: 550px;
      width: 100%;
      &-main{
        min-height: inherit;
      }
    }

    @include responsive(xs) {
      min-height: 230px;
      &-main {
        min-height: inherit;
      }
    }
    @include responsive(xxs) {
      max-height: 190px;
      height: 100%;
      &-main {
        max-height: inherit;
      }
    }

    &-main {
      padding: 30px;
      width: 100%;
      height: 100%;
      min-height: inherit ;
      @include flex(space-between, center, column);

      @include responsive(xs) {
        padding: 22px 16px;
      }
    }

    &-header {
      width: 100%;
      @include flex(space-between, center, row);
      padding-bottom: 82px;

      h2 {
        font-size: 40px;
        font-weight: $second-font-weight;

        @include responsive(sm) {
          font-size: 32px;
        }

        @include responsive(xs) {
          font-size: 22px;
        }
      }

      @include responsive(xs) {
        padding-bottom: 60px;
      }
    }

    &-button {
      font-size: 16px;
      font-weight: $second-font-weight;
      padding: 9px 30px;
      font-family: $font-family;
      background: $trenary-bg-gradient;
      border: 1px solid $green-color;
      box-shadow: 3px 3px 6px $dark-green-color ;
      border-radius: 22px;
      color: $secondary-color;
      transition: all 0.4s ease;

      &:hover {
        background-color: $primary-color;
        color: $primary-color;
        background: $secondary-color;
        box-shadow: 0px 0px 9px 1px $secondary-color;
        border-color: $secondary-color;
      }
      background-color: $secondary-color;
      @include responsive(xs) {
        padding: 9px 26px;
        font-weight: $font-weight;
      }
    }

    &-image {
      width: 64px;
      margin-left: auto;

      @include responsive(xs) {
        img {
          width: 50px;
        }
      }

      @include responsive(xxs) {
        img {
          width: 45px;
        }
      }
    }

    &-footer {
      width: 100%;
      @include flex(flex-start, flex-end, row);
      gap: 50px;

      h5 {
        font-size: 16px;
        margin-bottom: 9px;
        font-weight: $font-weight;
      }
    }

    &-specialists {
      @include flex(flex-start, flex-start, column);
      gap: 3px;
    }

    &-specialist {
      font-size: 18px;
      font-weight: $second-font-weight;

      @include responsive(xs) {
        font-size: 16px;
      }

      @include responsive(xxs) {
        font-size: 15px;
      }
    }

    &-overlay {
      width: 100%;
      height: 100%;
      z-index: 10;
      background: $primary-bg-gradient;
      backdrop-filter: blur(7.5px);
      padding: 30px;
      position: absolute;
      top: 100%;
      transition: top 0.4s ease;

      &.active--overlay {
        top: 0;
      }

      @include flex(flex-start, center, column);

      @include responsive(xs) {
        padding: 22px 16px;
      }

      &__header {
        width: 100%;
        @include flex(space-between, center, row);
        margin-bottom: 30px;

        @include responsive(md) {
          margin-bottom: 15px;
        }
      }

      &__title {
        font-size: 24px;
        font-weight: $second-font-weight;

        @include responsive(xs) {
          font-size: 20px;
        }
      }

      &__main {
        width: 100%;
        @include flex(space-between, flex-end, column);
      }

      &__button {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
    }

    &-description {
      font-weight: $font-weight;
      overflow-y: auto;
      text-transform: uppercase;
      margin-bottom: 20px;
      min-height: 100px;
      max-height: 110px;

      @include responsive(md) {
        min-height: 100px;
        max-height: 100px;
      }

      @include responsive(xxs) {
        font-size: 14px;
      }
    }
  }
}
