@import "./variables";
@import "./typography";
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgb(243, 239, 239);
}
::-webkit-scrollbar-thumb {
  background: $primary-color;
}
::-webkit-scrollbar-thumb:hover {
  background: green;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  overflow-x: hidden !important;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
  font-family: $font-family;
}

.container {
  max-width: 1206px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  @include responsive(xs) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include responsive(xxs) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

a,
i,
button,
svg {
  cursor: pointer;
}
