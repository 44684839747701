@import "../../scss/variables";

.hero {
    width: 100%;
    padding-top: 140px;
    padding-bottom: 368px;

    @include responsive(md) {
        padding-top: 100px;
        padding-bottom: 350px;
    }

    @include responsive(sm) {
        padding-top: 50px;
        padding-bottom: 250px;
    }

    @include responsive(xs) {
        padding-bottom: 116px;
    }

    @include responsive(xxs) {
        padding-top: 21px;
    }

    &__content {
        width: 100%;
    }

    &__info {
        max-width: 800px;
        @include flex(flex-start, flex-start, column);
        z-index: 99;
        position: relative;

        &-items {
            @include flex(flex-start, center, row);
            gap: 65px;
            margin-bottom: 102px;

            @include responsive(sm) {
                justify-content: center;
                gap: 20px;
                margin: 0 auto;
                margin-bottom: 50px;
                flex-wrap: wrap;
            }

            @include responsive(xxs) {
                align-items: center;
                text-align: center;
            }
        }

        &-item {
            gap: 4px;
            @include flex(flex-start, flex-start, column);

            h5 {
                font-weight: $second-font-weight;
            }

            p {
                font-size: 14px;
                line-height: 16px;
            }

            @include responsive(sm) {
                align-items: center;
                flex-basis: 48%;
            }

            @include responsive(xxs) {
                flex-basis: 100%;
            }
        }

        &-main {
            @include flex(flex-start, flex-start, column);

            @include responsive(sm) {
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            @include responsive(xxs) {
                align-items: center;
                text-align: center;
            }
        }
    }

    &__title {
        margin-bottom: 7px;

        @include responsive(sm) {
            font-size: 50px;
        }

        @include responsive(xs) {
            font-size: 29px;
            line-height: 1.3;
        }
    }

    &__description {
        margin-bottom: 29px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: $font-weight;
    }

    &__button {
        background-color: $secondary-color;
        color: $primary-color;
        padding: 16px 25px;
        max-width: 278px;
        font-size: 18px;
        font-weight: $second-font-weight;
        border: 1px solid $green-color;
        border-radius: 25px;
        transition: all 0.4s linear;
        display: inline-block;
        box-shadow: 0px 0px 9px $secondary-color;

        &:hover {
            background-color: $primary-color;
            color: $secondary-color;
            box-shadow: 0 0 100px 1px $secondary-color;
        }

        @include responsive(sm) {
            font-size: 16px;
        }
    }

    &__bg {
        position: absolute;
        bottom: -5px;
        right: 0;

        @include responsive(xxl) {
            right: -13%;
        }

        @include responsive(xxl) {
            right: -15%;
        }

        @include responsive(lg) {
            right: -30%;
        }

        @include responsive(md) {
            opacity: 0.7;
        }

        @include responsive(sm) {
            opacity: 0.4;
        }

        @include responsive(xs) {
            opacity: 0.3;
        }
    }

    &__icon {
        position: absolute;
        bottom: 35px;
        transform: translateX(-50%);
        left: 50%;

        svg {
            animation: animateIcon 2.3s ease infinite;
            height: 28px;
            width: 28px;
            color: $secondary-color;
        }
    }

    @keyframes animateIcon {
        0% {
            transform: translatey(0);
        }

        50% {
            transform: translateY(15px);
        }

        100% {
            transform: translateY(0);
        }
    }
}