@import "../../scss/variables";

.partners{
    padding-top: 36px;
    padding-bottom: 150px;
    background-color: $secondary-color;
    @include responsive(xxs) {
      padding-bottom: 120px;
    }
    &__content{
        width: 100%;
        @include flex(center,center,column);
    }
    &__title{
        font-size: 36px;
        font-weight: $second-font-weight;
        line-height: 1.3;
        margin-bottom: 66px;
        text-align: center;
        color: $primary-color;
        @include responsive(sm) {
          font-size: 28px;
        }
        @include responsive(xxs) {
            font-size: 24px;
            margin-bottom: 45px;   
          
        }
    }
    &__items{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 63px;
        @include responsive(xxs) {
          gap: 38px 20px;
        }
    }
    &__item{
        width: 220px;
        height: 65px;
        @include center();
        text-align: center;
        @include responsive(lg) {
          width: 185px;
          height: 60px;
        }
        @include responsive(xs) {
          width: 145px;
          height: 45px;
        }
    }
}