@import "../../scss/variables";

.footer {
    background:$footer-main-bg-gradient;
    padding-top: 54px;

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__main {
        width: 100%;
        margin-bottom: 60px;

        &-content {
            width: 100%;
            @include flex(space-between, flex-start, row);
            @include responsive(xs) {
               flex-wrap: wrap
            }
        }
    }

    &__info {
        @include flex(flex-start, flex-start, column);
        gap: 15px;
        max-width: 240px;
        @include responsive(sm) {
           max-width: 180px;
        }
        @include responsive(xs) {
            max-width: 100%;
            margin-bottom: 30px;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 26px;
        font-weight: $second-font-weight;
    }

    &__navigation {
        @include flex(center, flex-start, row);
        color: $secondary-color;
        gap: 200px;
        @include responsive(md) {
           gap: 150px;
        }
        @include responsive(sm) {
           gap: 55px;
        }
        @include responsive(xxs) {
           flex-direction: column
        }
      
        &-links {
            @include flex(flex-start, flex-start, column);
            gap: 40px;
            @include responsive(sm) {
               gap: 35px;
            }
            @include responsive(xxs) {
               gap: 30px;
            }
        }

        &-link {
            color: $secondary-color;
            font-size: 18px;
            line-height: 26px;
            text-transform: uppercase;
            &:hover{
                text-decoration: underline 1px solid;
            }

            @include responsive(xxs) {
               font-size: 16px;
            }
        }
    }
    &__line{
        width: 100%;
        background:  $grey-color;
        height: 1px;
    }
    &__other{
        width: 100%;
    }
    &__text{
        color: $grey-color;
        line-height: 1.3;
        padding: 25px;
        padding-bottom: 27px;
        text-align: center;
        font-weight: $second-font-weight;
        @include responsive(xs) {
            padding: 25px 0;
            font-size: 14px;    
        }
    }

}