h1 {
    font-size: 56px;
    line-height: 52px;
}

h2 {
    font-size: 3rem;
    line-height: 1;
}

h3 {
    font-size: 36px;
}

span,
p,
a {
    font-size: 16px;
}